@import '../../Layout/variables.scss';

.menu-wrapper {
  width: 100%;
  height: 3px;
  justify-content: flex-end;
  align-items: flex-end;
  z-index: 0;
  .menu-overlay {
    position: fixed;
    width: 0;
    top: 0;
    left: 0;
    z-index: -1;
    background-color: $primary-500;
    height: 100vh;
    transition: 0.4s ease width;
  }
  .menu-links {
    width: 100%;
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    visibility: hidden;
    opacity: 0;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    ul {
      z-index: 3;
      font-family: $primary-fonts;
      font-weight: 900;
      font-size: 5rem;
      li {
        text-align: center;
        list-style: none;
        color: $primary-100;
        font-size: 4rem;
        padding: 1rem 0 0 1rem;

        .nav-link,
        .curr {
          &:after {
            content: '';
            position: absolute;
            height: 8px;
            background-color: #fff;
            width: 0;
            left: 50%;
            bottom: 0;
            transform: translateX(-50%);
            transition: 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) width;
          }
        }
        .nav-link {
          position: relative;
          color: $primary-100;
          &:hover {
            &:after {
              width: 100%;
            }
          }
        }
      }
    }
  }

  .toggler {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    cursor: pointer;
    width: 50px;
    height: 50px;
    opacity: 0;
    &:checked {
      & ~ .menu-overlay {
        transition: 0.4s ease width;
        width: 100%;
        overflow: hidden;
      }
      & ~ .menu-links {
        transition: opacity 0.4s ease 0.3s;
        opacity: 1;
        visibility: visible;
      }
      & + .menu-hamburger {
        & > div {
          &:before,
          &:after {
            transition: transform 0.4s ease, background-color 0.2s ease 0.2s;
            background: #fff;
            top: 0;
            transform: rotate(225deg);
          }
          &:after {
            transform: rotate(315deg);
          }
        }
      }
      &:hover {
        & + .hamburger > div {
          transform: rotate(225deg);
        }
      }
    }
  }
  .menu-hamburger {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    width: 60px;
    height: 60px;
    padding: 1rem;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    & > div {
      position: relative;
      flex: none;
      width: 100%;
      background: $primary-500;
      display: flex;
      align-items: center;
      justify-content: center;
      // transition: 0.4s ease;
      &:before,
      &:after {
        transition: 0.2s ease transform;
        content: '';
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 4px;
        background: inherit;
      }
      &:after {
        transform: rotate(90deg);
      }
    }
  }
}

@media (min-width: 736px) {
  .menu-wrapper {
    .toggler {
      display: none;
    }
    .menu-overlay {
      display: none;
    }
    .menu-hamburger {
      display: none;
    }
    .menu-links {
      padding-bottom: 0.8rem;
      width: auto;
      top: 0;
      right: 0;
      opacity: 1;
      visibility: visible;
      position: absolute;
      justify-content: flex-end;
      ul {
        display: flex;
        li {
          padding-left: 1.4rem;
          text-align: right;
          .nav-link {
            padding: 0.4rem 0.8rem;
            color: #000;
            font-weight: 700;
            font-size: 0.7rem;
            text-transform: uppercase;
            letter-spacing: 2px;
            border-radius: 0.2rem;
            transition: color 0.2s ease-in-out 0.1;
            &:hover {
              color: $primary-500;
            }
          }
          .curr {
            color: $primary-100;
            background: $primary-500;
            &:hover {
              background: $primary-500;
              color: white;
            }
          }
          .nav-link,
          .curr {
            &:after {
              background: transparent;
            }
          }
        }
      }
    }
  }
}
