@import '../Layout/variables.scss';

.contact {
  .form-group {
    input,
    textarea {
      font-size: 1rem;
      font-family: $primary-fonts;
      padding: 1rem;
      width: 100%;
      border: none;
      margin: 1rem 0;
      background: transparent;
      border-bottom: 1px solid #ccc;
      transition: 0.2s ease-in-out;

      &:focus {
        outline: none;
        color: black;
        border-bottom: 1px solid $primary-500;
      }
    }
  }
}

.Success,
.Error {
  text-align: center;
}
