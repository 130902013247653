@import './Layout/variables.scss';

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  color: #000;
  a {
    color: #000;
    cursor: pointer;
  }
}

::selection {
  background: $primary-500; /* WebKit/Blink Browsers */
  color: #fff;
}
::-moz-selection {
  background: $primary-500; /* Gecko Browsers */
  color: #fff;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $primary-fonts;
  font-weight: 900;
  margin-bottom: 0.4rem;
}

p,
ul {
  font-family: $primary-fonts;
  font-size: 1rem;
  line-height: 1.4;
  margin-bottom: 0.8rem;
}

a {
  text-decoration: none;
}

.emphasize {
  font-style: italic;
}

.container {
  max-width: 964px;
  margin: 2rem auto;
  padding: 2rem;
}

button,
form input[type='submit'] {
  outline: none;
  border: none;
  cursor: pointer;
  background-color: $primary-500;
  color: $primary-100;
  font-family: $primary-fonts;
  font-weight: 700;
  font-size: 1rem;
  padding: 0.8rem 4rem;
  border-radius: 0.2rem;

  &:hover {
    background-color: $primary-600;
  }
}

@media (max-width: 500px) {
  button,
  form input[type='submit'] {
    padding: 1.2rem 1rem;
    width: 100%;
    font-size: 1.2rem;
  }
}
