.Display {
  .flex {
    display: flex;
    // grid-template-columns: repeat(2, 1fr);
    // grid-gap: 1rem;
    width: 100%;
    justify-content: center;
    justify-items: center;
    align-items: center;
    div {
      margin: 0 0.5rem;
      img {
        height: 75vh;
      }
    }
  }
  .caption {
    margin-top: 0.4rem;
    text-align: center;
    font-size: 0.6rem;
    color: rgb(77, 77, 77);
  }
}

@media (max-width: 550px) {
  .Display {
    .flex {
      flex-direction: column;
    }
    div {
      img {
        margin: 0.5rem 0;
      }
    }
  }
}
