@import '../../Layout/variables.scss';

.work-thumbnail {
  cursor: pointer;
  opacity: 1;
  .work-thumbnail-image {
    width: 100%;
    height: 300px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  &:hover {
    opacity: 0.8;
  }
}
