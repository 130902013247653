@import '../../Layout/variables.scss';

.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin-bottom: 2rem;

  .header-brand {
    display: flex;
    .logo {
      margin-top: 0.1rem;
      cursor: pointer;
      height: 60px;
      margin-right: 1rem;
    }
    h1 {
      font-weight: 600;
      line-height: 2rem;
      font-size: 2.4rem;
    }
  }
}

@media (max-width: 350px) {
  .header-container {
    .header-brand {
      .logo {
        height: 50px;
      }
      h1 {
        font-size: 2rem;
        line-height: 1.8rem;
      }
    }
  }
}
