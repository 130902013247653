@import '../../Layout/variables.scss';

.work-intro-wrapper {
  display: grid;
  grid-template-columns: 1fr min-content;
  grid-gap: 2rem;

  .work-intro-img {
    grid-column: 1 / span 2;
    width: 100%;
  }

  .work-intro-text {
    margin: 1rem 0;
    grid-column-end: auto;
    .work-intro-title {
      font-size: 3rem;
      line-height: 3rem;
      margin-bottom: 1rem;
    }
    .work-intro-subtitle {
      font-family: $secondary-fonts;
      font-size: 1rem;
      text-transform: uppercase;
      letter-spacing: 3px;
      margin-bottom: 0.8rem;
    }
  }
}

@media all and (max-width: 650px) {
  .work-intro-wrapper {
    .work-intro-img {
      grid-column: 1 / span 6;
    }
    .work-intro-text {
      grid-column: 1 / span 6;
    }
  }
}
