@import '../Layout/variables.scss';

.About {
  ul {
    list-style-position: inside;
    margin-left: 1rem;
  }
  a {
    color: $primary-600;
    &:hover {
      color: $primary-800;
    }
  }
  .bio-image {
    float: right;
    margin: 1rem 0 0rem 1.5rem;
    width: 300px;
    border-radius: 0.2rem;

    .bio-image-container {
      background: url('../img/charlieandme.jpg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 0.2rem;
      width: 100%;
      height: 300px;
      transition: background 0.2s ease-in-out;

      &:hover {
        background: url('../img/charlieandme_2.jpg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
    .bio-image-caption {
      padding: 0.4rem 0.4rem 0.6rem 0.4rem;
      text-align: center;
      font-size: 0.7rem;
      font-weight: 700;
      margin: 0;
    }
  }

  .about-list {
    margin-top: 2rem;
  }
}

@media (max-width: 600px) {
  .About {
    .bio-image {
      width: 100%;
      float: none;
      margin: 0 auto 1.5rem auto;
    }
    .about-intro {
      grid-column: 1 / span 3;
    }
    .about-list {
      grid-column: 1 / span 3;
      margin-top: 2rem;
      margin-left: 0;
    }
  }
}
