.not-found {
  width: 100%;
  h2,
  h4 {
    text-align: center;
  }

  .jez-404 {
    display: block;
    width: 100%;
    margin: auto;
  }
}
