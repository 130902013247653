$primary-fonts: 'Livvic', Helvetica, sans-serif;
$secondary-fonts: Menlo, Consola, monospace;

$primary-100: #fff5fe;
$primary-200: #ffccfb;
$primary-300: #ff99f0;
$primary-400: #ff66e3;
$primary-500: #ff36c2;
$primary-600: #ff00b3;
$primary-700: #cc0088;
$primary-800: #99006b;
$primary-900: #660050;
