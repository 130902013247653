.work {
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-gap: 2rem;
  margin-bottom: 2rem;
}

@media (max-width: 800px) {
  .work {
    grid-gap: 1rem;
  }
}

@media (max-width: 650px) {
  .work {
    grid-template-columns: repeat(1, auto);
  }
}
